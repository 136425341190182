<template>
  <v-layout class="d-block">
    <v-row class="mb-12">
      <v-col cols="12">
        <v-card outlined class="card-hover fill-height">
          <v-app-bar dense elevation="0" flat>
            <h3 class="font-weight-bold">{{ $t("storage.titles.extraData") }}</h3>
            <v-spacer></v-spacer>
            <v-img
              class="mx-4"
              :src="require('@/assets/icons/firstline.svg')"
              contain
              height="25px"
              max-height="25px"
              width="25px"
              max-width="25px"
            />
          </v-app-bar>
            <v-card-text>
              <v-row v-if="action === 'show'">
                <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                  <div style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h3 class="mr-2 font-weight-bold">{{ $t("storage.fields.minimumHeight") }}:</h3>
                    {{ item.minHeight || '-' }}
                  </div>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                  <div style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h3 class="mr-2 font-weight-bold">{{ $t("storage.fields.mediumHeight") }}:</h3>
                    {{ item.mediumHeight || '-' }}
                  </div>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                  <div style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h3 class="mr-2 font-weight-bold">{{ $t("storage.fields.maximumHeight") }}:</h3>
                    {{ item.maxHeight || '-' }}
                  </div>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                  <div style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h3 class="mr-2 font-weight-bold">{{ $t("storage.fields.mediumTemperature") }}:</h3>
                    {{ item.mediumTemperature || '-' }}
                  </div>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                  <div style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h3 class="mr-2 font-weight-bold">{{ $t("storage.fields.locationOwner") }}:</h3>
                    {{ item.locationOwner || '-' }}
                  </div>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                  <div style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h3 class="mr-2 font-weight-bold">{{ $t("storage.fields.classificationType") }}:</h3>
                    <div>
                      <div v-for="(classification, index) in findClassifications(item)" :key="index">
                        {{ classification }}
                      </div>
                      <div v-if="!findClassifications(item).length">-</div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-form v-if="['new', 'edit'].includes(action)" ref="formMain" v-model="valid.form">
                <v-row>
                  <v-col cols="4" class="ma-0 pa-0 my-2 px-4">
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <v-text-field
                      v-model="item.mediumHeight"
                      :rules="heightValidationRules('medium')"
                      color="primary"
                      onkeypress="return (event.charCode != 34)"
                      dense
                      outlined
                      type="number"
                      :hint="mediumHeightHint"
                      persistent-hint
                      class="required"
                    >
                      <template v-slot:label>
                        {{ $t("storage.fields.mediumHeight") }}
                      </template>
                    </v-text-field>
                  </span>
                  </v-col>
                  <v-col cols="4" class="ma-0 pa-0 my-2 px-4">
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <v-text-field
                      v-model="item.minHeight"
                      :rules="heightValidationRules('min')"
                      color="primary"
                      onkeypress="return (event.charCode != 34)"
                      required
                      dense
                      outlined
                      type="number"
                      :hint="minHeightHint"
                      persistent-hint
                      class="required"
                    >
                      <template v-slot:label>
                        {{ $t("storage.fields.minimumHeight") }}
                        <strong v-if="isHeightRequired" class="red--text">*</strong>
                      </template>
                    </v-text-field>
                  </span>
                  </v-col>
                  <v-col cols="4" class="ma-0 pa-0 my-2 px-4">
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <v-text-field
                      v-model="item.maxHeight"
                      :rules="heightValidationRules('max')"
                      color="primary"
                      onkeypress="return (event.charCode != 34)"
                      required
                      dense
                      outlined
                      type="number"
                      :hint="maxHeightHint"
                      persistent-hint
                      class="required"
                    >
                      <template v-slot:label>
                        {{ $t("storage.fields.maximumHeight") }}
                        <strong v-if="isHeightRequired" class="red--text">*</strong>
                      </template>
                    </v-text-field>
                  </span>
                  </v-col>
                  <v-col cols="4" class="ma-0 pa-0 my-2 px-4">
                    <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-text-field
                        v-model="item.mediumTemperature"
                        :label="$t('storage.fields.mediumTemperature')"
                        class="required"
                        color="primary"
                        onkeypress="return (event.charCode != 34)"
                        required
                        dense
                        outlined
                        type="number"
                      />
                    </span>
                  </v-col>
                  <v-col cols="4" class="ma-0 pa-0 my-2 px-4">
                    <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-text-field
                        v-model="item.locationOwner"
                        :label="$t('storage.fields.locationOwner')"
                        class="required"
                        color="primary"
                        onkeypress="return (event.charCode != 34)"
                        required
                        dense
                        outlined
                        type="text"
                      />
                    </span>
                  </v-col>
                  <v-col cols="4" class="ma-0 pa-0 my-2 px-4">
                    <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-autocomplete
                        v-model="item.classificationTypes"
                        :items="classifications"
                        :label="$t('storage.fields.classificationType')"
                        item-value="id"
                        item-text="name"
                        item-color="primary"
                        class="required"
                        color="primary"
                        required
                        dense
                        outlined
                        multiple
                        chips
                        small-chips
                        deletable-chips
                        clearable
                      >
                        <template v-slot:selection="{ item: classification, index }">
                          <v-chip
                            v-if="index === 0"
                            small
                            close
                            @click:close="removeClassification(classification.id)"
                            class="mr-1"
                          >
                            {{ classification.name }}
                          </v-chip>
                          <span v-else-if="index === 1" class="grey--text text-caption">
                            +{{ item.classificationTypes.length - 1 }} {{ $t('common.titles.others')  }}
                          </span>
                        </template>
                      </v-autocomplete>
                    </span>
                  </v-col>
                </v-row>
              </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
  import _ from "lodash";
  import { mapGetters } from "vuex";
  import LanguajeService from "@/services/LanguajeService";
  import Rules from "@/module/configuration/storage/validations";
  import NumberFormatService from "@/services/NumberFormatService";

  export default {
    components: {},
    name: "TabExtraDataLocation",
    props: {
      item: {
        type: Object,
        required: true,
      },
      action: {
        type: String,
        required: false,
        default: "show",
      },
    },

    data() {
      return {
        valid: {
          form: true, // Inicializar como true por defecto
          heights: true // Inicializar como true por defecto
        }
      };
    },

    watch: {
      'item.mediumHeight'(newValue) {
        if (newValue === "") {
          this.item.mediumHeight = null;
        }
        this.$nextTick(() => this.validateAllHeights());
      },
      'item.minHeight'(newValue) {
        if (newValue === "") {
          this.item.minHeight = null;
        }
        this.$nextTick(() => this.validateAllHeights());
      },
      'item.maxHeight'(newValue) {
        if (newValue === "") {
          this.item.maxHeight = null;
        }
        this.$nextTick(() => this.validateAllHeights());
      },
      'item.mediumTemperature'(newValue) {
        if (newValue === "") {
          this.item.mediumTemperature = null;
        }
      },
      valid: {
        handler(val) {
          // Emit validation state to parent component
          this.$emit('update:validation', val.form && val.heights);
        },
        deep: true,
        immediate: true
      },
      // Agregar watcher para item
      item: {
        handler() {
          this.$nextTick(() => {
            this.validateHeightConstraints();
            this.validateAllHeights();
          });
        },
        deep: true,
        immediate: true
      }
    },

    async created() {
      await this.$store.dispatch('storage/fetchClassificationTypes');
      
      // Migrar de classificationType a classificationTypes si es necesario
      if (this.item.classificationType && !this.item.classificationTypes) {
        this.$set(this.item, 'classificationTypes', this.item.classificationType ? [this.item.classificationType] : []);
      } else if (!this.item.classificationTypes) {
        this.$set(this.item, 'classificationTypes', []);
      }
      
      // Realizar validación inicial si no es modo show
      if (this.action !== 'show') {
        this.$nextTick(() => {
          this.validateHeightConstraints();
          this.validateAllHeights();
        });
      }
      
      // Emitir estado de validación inicial
      this.$emit('update:validation', true);
    },

    computed: {
      ...mapGetters({
        onlyPositiveNumberFormat: "general/onlyPositiveNumberFormat",
        classifications: "storage/GET_CLASSIFICATIONS",
        minMaxHeightRules: "general/validateMoreThan",
        requireRules: "general/requireRules",
      }),
      
      companyHeightConfig() {
        // Access the height configuration from the config
        const CONFIG = JSON.parse(localStorage.getItem("config"));

        const configLocations = CONFIG && CONFIG.company && CONFIG.company.locations 
          ? CONFIG.company.locations
          : {
            minHeight: null,
            maxHeight: null
          };
          
        // Use CONFIG values, no default values needed here
        return {
          minHeight: configLocations.minHeight,
          maxHeight: configLocations.maxHeight
        };
      },

      isHeightRequired() {
        // If there are configured height values, the fields should be required
        return this.companyHeightConfig.minHeight || this.companyHeightConfig.maxHeight;
      },

      minHeightHint() {
        return Rules.getHeightHint('min', this.item, this.companyHeightConfig);
      },

      maxHeightHint() {
        return Rules.getHeightHint('max', this.item, this.companyHeightConfig);
      },
      
      mediumHeightHint() {
        return Rules.getHeightHint('medium', this.item, this.companyHeightConfig);
      },
    },

    methods: {
      findClassifications(item) {
        if (!item.classificationTypes || !Array.isArray(item.classificationTypes) || !item.classificationTypes.length) {
          return [];
        }
        
        return item.classificationTypes.map(typeId => {
          const classification = _.find(this.classifications, { id: typeId });
          return classification ? this.translate(classification.languageKey) : '';
        }).filter(Boolean);
      },
      
      // Mantener el método antiguo para compatibilidad
      findClassification(item) {
        if (this.findClassifications(item).length) {
          return this.findClassifications(item).join(", ");
        } else {
          return '-';
        }
      },
      translate(key) {
        return LanguajeService.getKey3(key);
      },
      
      heightValidationRules(type) {
        // Get the standard height validation rules
        const heightRules = Rules.getHeightValidationRules(type, this.item, this.companyHeightConfig);
        
        // If height configuration exists, add required rule only for min/max fields
        if (this.isHeightRequired && type !== 'medium') {
          return [...this.requireRules, ...heightRules];
        }

        return heightRules;
      },

      validateAllHeights() {
        // Force update of form references and validation
        if (this.$refs.formMain) {
          const inputs = this.$refs.formMain.$el.querySelectorAll('.v-text-field');
          inputs.forEach(input => {
            const vm = input.__vue__;
            if (vm && vm.validate) {
              vm.validate();
            }
          });
          
          // After validation, update overall form validity
          this.$nextTick(() => {
            if (this.$refs.formMain) {
              this.$refs.formMain.validate();
              
              // Check specifically the height fields validation
              this.validateHeightConstraints();
            }
          });
        }
      },

      validateHeightConstraints() {
        const { minHeight, mediumHeight, maxHeight } = this.item;
        
        // Si no hay valores configurados, considerar válido
        if (!minHeight && !maxHeight && !mediumHeight) {
          this.valid.heights = true;
          return;
        }
        
        let isValid = true;
        
        if (minHeight !== null && mediumHeight !== null && maxHeight !== null) {
          isValid = Number(minHeight) <= Number(mediumHeight) && 
                   Number(mediumHeight) <= Number(maxHeight);
        } else if (minHeight !== null && maxHeight !== null) {
          isValid = Number(minHeight) <= Number(maxHeight);
        }

        this.valid.heights = isValid;
        
        // Emitir el estado de validación actual
        this.$emit('update:validation', this.valid.form && this.valid.heights);
      },

      removeClassification(id) {
        const index = this.item.classificationTypes.indexOf(id);
        if (index >= 0) {
          const newTypes = [...this.item.classificationTypes];
          newTypes.splice(index, 1);
          this.$set(this.item, 'classificationTypes', newTypes);
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .fill-icon__color {
    filter: invert(20%) sepia(0%) saturate(0%) hue-rotate(180deg)
      brightness(400%) contrast(88%);
  }
  .card-hover {
    box-shadow: none;
    transition: all 200ms ease-in-out;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
  }

  :deep(.v-input--selection-controls.v-input) {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between !important;
    align-items: center !important;
  }

  :deep(.v-input--hide-details > .v-input__control > .v-input__slot) {
    display: contents;
  }

  :deep(.v-input__control) {
    align-items: flex-end;
  }

  :deep(.theme--light.v-label) {
    left: 0px;
    right: auto;
    position: relative;
  }
</style>
