<template>
  <v-layout class="d-block">
    <v-row class="mb-12">
      <v-col cols="12">
        <v-card class="card-hover fill-height" outlined>
          <v-app-bar dense elevation="0" flat>
            <h3 class="font-weight-bold">{{ $t("storage.titles.producers") }}</h3>
            <v-spacer></v-spacer>
            <v-img
              :src="require('@/assets/icons/firstline.svg')"
              class="mx-4"
              contain
              height="25px"
              max-height="25px"
              max-width="25px"
              width="25px"
            />
          </v-app-bar>
          <v-card-text>
            <v-row v-if="action === 'show'">
              <v-col cols="12">
                <v-data-iterator
                  :items="producerDetails"
                  :items-per-page="itemsPerPage"
                  :loading="loading"
                  :no-data-text="$t('common.ui.not_data_found')"
                  :no-results-text="$t('common.ui.not_search_found')"
                  class="elevation-0"
                  :loading-text="$t('common.notification.loadData')"
                  checkbox-color="primary"
                  :footer-props="{
                    ...rowsPerPageItemsOptions,
                    itemsPerPageText: $t('common.table.itemsPerPageText'),
                    pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}'
                  }"
                >
                  <template v-slot:default="{ items }">
                    <v-row>
                      <v-col
                        v-for="producer in items"
                        :key="producer.id"
                        cols="3"
                      >
                        <v-card elevation="0" flat outlined>
                          <v-card-text>
                            <div v-for="(value, key) in producer"
                                 v-if="!['id', 'observations', 'storageDevices', 'companyId', 'isDeleted', 'isActive'].includes(key)"
                                 :key="key">
                              <strong>{{ $t(`producer.fields.${key}`) }}</strong>: {{ value || '-' }}
                            </div>
                            <div>
                              <strong>{{ $t(`producer.fields.isActive`) }}</strong>:
                              <v-chip v-if="producer.isActive" color="success lighten-5" class="success--text font-weight-bold" x-small>
                                {{ $t('storage.fields.isActive.yes') }}
                              </v-chip>
                              <v-chip v-else  color="error lighten-5" class="error--text font-weight-bold" x-small>
                                {{ $t('storage.fields.isActive.no') }}
                              </v-chip>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import LanguageService from "@/services/LanguajeService";
import ProducerService from "@/services/ProducerService";

const PROFILE = JSON.parse(localStorage.getItem("profile"))

export default {
  components: {},
  name: "TabProducerLocation",
  props: {
    item: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: false,
      default: "show",
    },
  },

  data: () => ({
    itemsPerPage: 8,
    pagination: {
      page: 1
    },
    loading: false,
    error: false,
    producerDetails: [],
    rowsPerPageItemsOptions: {
      itemsPerPageOptions: [8, 16, 32, -1],
    },
  }),

  mounted() {
    if (this.item.producers.length > 0) {
      this.fetchProducerDetails();
    }
  },

  computed: {
    ...mapGetters({
      producers: 'producer/GET_PRODUCERS'
    }),
  },

  methods: {
    ...mapActions({
      fetchListProducer: "producer/fetchListProducer",
    }),
    async fetchProducerDetails() {
      this.loading = true;
      this.error = false;
      try {
        const producerIds = this.item.producers.map(p => p.id);
        const response = await ProducerService.producers({
          ids: producerIds,
          pageableDTO: {
            page: 0,
            size: 1000,
            sortBy: 'name',
            direction: 'asc',
          }
        });
        this.producerDetails = response.data.content;
      } catch (error) {
        console.error("Failed to fetch producer details", error);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    translate(key) {
      return LanguageService.getKey3(key);
    },
  },
}
</script>

<style lang="scss" scoped>
.v-pagination {
  box-shadow: none !important;
}

.fill-icon__color {
  filter: invert(20%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(400%) contrast(88%);
}

.card-hover {
  box-shadow: none;
  transition: all 200ms ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }
}
</style>
