<template>
  <div class="fill-height">
    <v-container fluid class="fill-height">
      <v-row class="fill-height">
        <v-col cols="12" class="pa-0">
          <v-card elevation="0">
            <v-overlay
              v-show="loading"
              color="white"
              opacity="1"
              absolute
            >
              <v-progress-circular
                class="mx-auto"
                indeterminate
                color="primary"
              />
            </v-overlay>

            <!-- Height validation alert -->
            <v-alert
              v-if="heightValidation.show && ['new', 'edit'].includes(action) && tabs !== 7"
              text
              outlined
              color="deep-orange"
              class="mx-4 mb-2 validation-alert"
            >
              <div class="d-flex align-center mb-3">
                <v-icon color="deep-orange" class="mr-2">mdi-alert-octagon</v-icon>
                <span class="font-weight-bold">{{ $t('storage.validation.heightErrors') }}</span>
              </div>
              
              <template v-for="(group, tabIndex) in heightValidation.errorGroups">
                <div 
                  v-if="group.errors.length > 0" 
                  :key="tabIndex"
                  class="validation-group mb-3 d-flex justify-space-between"
                >
                  <div>
                    <div class="d-flex align-center mb-2">
                      <v-img
                        class="mr-2"
                        :src="require('@/assets/icons/firstline.svg')"
                        contain
                        height="15px"
                        max-height="15px"
                        width="15px"
                        max-width="15px"
                      />
                      <span class="group-title">{{ group.title }}</span>
                    </div>
                    
                    <div class="validation-errors pl-6">
                      <div 
                        v-for="(error, errorIndex) in group.errors" 
                        :key="errorIndex"
                        class="validation-error"
                      >
                        <div class="d-flex align-items-start mb-2">
                          <span class="error-message">{{ error }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-btn
                    x-small
                    text
                    color="primary"
                    class="ml-6 mt-1"
                    @click="goToTab(7)"
                  >
                    {{ $t('storage.validation.goToField') }}
                    <v-icon x-small class="ml-1">mdi-arrow-right</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-alert>
            
            <v-tabs
              color="neuter" background-color="gray"
              v-model="tabs"
              hide-slider
              height="60"
            >
              <v-tab
                v-show="action === 'show'"
                class="ma-0 py-0 px-4"
              >
                {{ $t("locations.titles.preview") }}
              </v-tab>

              <v-tab
                class="ma-0 py-0 px-4"
              >
                {{ $t("locations.titles.main") }}
              </v-tab>
              <v-tab
                class="ma-0 py-0 px-4"
              >
                {{ $t("locations.titles.map") }}
              </v-tab>

              <v-tab
                class="ma-0 py-0 px-4"
              >
                {{ $t("locations.titles.media") }}
              </v-tab>

              <v-tab
                class="ma-0 pa-0 px-4"
                v-show="integrations.BRAINAG && (checkAdmin() ||
                (check([{ domain: 'CheckSocioambiental', permisions: ['Read'] }, { domain: 'CheckSocioambiental', permisions: ['Write'] }])))"
              >
                {{ $t("locations.titles.checkSocioambiental") }}
              </v-tab>

              <v-tab
                class="ma-0 pa-0 px-4"
              >
                {{ $t("storage.titles.badgeName") }}
              </v-tab>

              <v-tab
                v-show="action === 'show'"
                class="ma-0 pa-0 px-4"
              >
                {{ $t("storage.titles.producers") }}
              </v-tab>

              <v-tab
                class="ma-0 pa-0 px-4"
              >
                {{ $t("storage.titles.extraData") }}
              </v-tab>

              <v-tab
                class="ma-0 pa-0 px-4"
                v-show="action === 'show'"
              >
                {{ $t("reports.titles.documents") }}
              </v-tab>

              <v-tab
                class="ma-0 pa-0 px-4"
                v-show="integrations.WALLET"
              >
                {{ $t("company.titles.configurations") }}
              </v-tab>

              <v-tabs-items v-model="tabs">
                <v-tab-item :value="0">
                  <v-card flat>
                    <v-card-text>
                      <TabPreviewLocation :item="editedItem" :loading="!loading" />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item v-show="tabs === 1">
                  <v-card flat>
                    <v-card-text>
                      <TabMainLocation
                        ref="tabMainLocation"
                        v-if="action === 'new' || editedItem.id"
                        @update:validation="forms.main = $event"
                        :action="action"
                        :item="editedItem"
                        :is-ready="isReady"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item v-show="tabs === 2">
                  <v-card flat>
                    <v-card-text>
                      <TabMapLocation
                        ref="tabMapLocation"
                        @update:validation="forms.map = $event"
                        @update:turf="turfDefaultUnit = $event"
                        @update:model="updateModel($event)"
                        :action="action"
                        :item="editedItem"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item v-show="tabs === 3">
                  <v-card flat>
                    <v-card-text>
                      <TabMediaLocation
                        :action="action"
                        :item="editedItem"
                        @update:item="$set(editedItem, $event)"
                        @update:images="updateImages($event)"
                        @update:logo="updateLogo($event)"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item
                  v-show="tabs === 4 && (integrations.BRAINAG && (checkAdmin() ||
                (check([{ domain: 'CheckSocioambiental', permisions: ['Read'] }, { domain: 'CheckSocioambiental', permisions: ['Write'] }]))))"
                >
                  <v-card flat>
                    <v-card-text>
                      <TabCheckLocation :action="action" :item="editedItem" />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item v-show="tabs === 5">
                  <v-card flat>
                    <v-card-text>
                      <TabBadgeLocation
                        :action="action"
                        :item="editedItem"
                        @update:item="$set(editedItem, $event)"
                        @update:badgesFiles="updateBadgesFiles($event)"
                        @update:badges="updateBadges($event)"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item v-show="tabs === 6">
                  <v-card flat>
                    <v-card-text>
                      <TabProducerLocation
                        :action="action"
                        :item="editedItem"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item v-show="tabs === 7">
                  <v-card flat>
                    <v-card-text>
                      <TabExtraDataLocation
                        ref="tabExtraLocation"
                        :action="action"
                        :item="editedItem"
                        @update:validation="forms.extraData = $event"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item v-show="tabs === 8 && action === 'show'"">
                  <v-card flat>
                    <v-card-text>
                      <TabDocumentsLocation
                        :action="action"
                        :item="editedItem"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item v-show="tabs === 9 && integrations.WALLET"">
                  <v-card flat>
                    <v-card-text>
                      <TabConfigurationLocation
                        :action="action"
                        :item="editedItem"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-toolbar
      bottom
      style="position: fixed"
      max-width="100%"
      width="-webkit-fill-available"
      class="px-6"
    >
      <v-switch
        class="mx-2"
        v-model="editedItem.active"
        color="primary"
        hide-details
        :disabled="action === 'show' || isVersion"
      >
        <template v-slot:prepend>
          <v-label ref="label">{{ $t("locations.fields.active") }} </v-label>
        </template>
      </v-switch>
      <v-switch
        class="mx-2"
        v-model="editedItem.productive"
        color="primary"
        hide-details
        :disabled="action === 'show' || isVersion"
      >
        <template v-slot:prepend>
          <v-label ref="label" class="full-width"
            >{{ $t("storage.fields.prd") }}
          </v-label>
        </template>
      </v-switch>
      <v-spacer />
      <span v-if="['new', 'edit'].includes(action)">
        <v-btn small text color="neuter" @click="back()">{{
          $t("common.buttons.cancel")
        }}</v-btn>
        <v-btn small elevation="0" color="primary" class="t-bw-primary--text ml-2" @click="save()" :disabled="!valid || heightValidation.invalid">{{
          $t("common.buttons.confirm")
        }}</v-btn>
      </span>
      <span v-else>
        <v-btn small elevation="0" color="primary" class="t-bw-primary--text" @click="back()">{{
          $t("common.buttons.back")
        }}</v-btn>
      </span>
    </v-toolbar>
  </div>
</template>

<script>
  import _ from "lodash"
  import Pbf from "pbf"
  import geobuf from "geobuf"
  import { mapGetters, mapActions } from "vuex"
  import LanguageService from "@/services/LanguajeService"
  import TabPreviewLocation from "@/module/configuration/storage/components/tabs/items/TabPreviewLocation.vue"
  import TabMainLocation from "@/module/configuration/storage/components/tabs/items/TabMainLocation.vue"
  import TabMapLocation from "@/module/configuration/storage/components/tabs/items/TabMapLocation.vue"
  import TabMediaLocation from "@/module/configuration/storage/components/tabs/items/TabMediaLocation.vue"
  import TabBadgeLocation from "@/module/configuration/storage/components/tabs/items/TabBadgeLocation.vue"
  import TabCheckLocation from "@/module/configuration/storage/components/tabs/items/TabCheckLocation.vue"
  import TabConfigurationLocation from "@/module/configuration/storage/components/tabs/items/TabConfigurationLocation.vue"
  import TabDocumentsLocation from "@/module/configuration/storage/components/tabs/items/TabDocumentsLocation.vue"
  import TabExtraDataLocation from "@/module/configuration/storage/components/tabs/items/TabExtraDataLocation.vue"
  import MapService from "@/services/MapService"
  import LanguajeService from "@/services/LanguajeService"
  import PermissionService from "@/services/PermisionService";
  import TabProducerLocation from "@/module/configuration/storage/components/tabs/items/TabProducerLocation.vue";
  import { getByProperty } from "@/utils"
  import NumberFormatService from "@/services/NumberFormatService";

  const PROFILE = JSON.parse(localStorage.getItem("profile"))

  export default {
    components: {
      TabProducerLocation,
      TabPreviewLocation,
      TabMainLocation,
      TabMapLocation,
      TabMediaLocation,
      TabBadgeLocation,
      TabCheckLocation,
      TabConfigurationLocation,
      TabDocumentsLocation,
      TabExtraDataLocation,
    },
    props: {
      uuid: String,
      action: String,
    },
    data: () => ({
      tabs: null,
      loading: true,
      turfDefaultUnit: null,
      editedIndex: null,
      isVersion: false,
      forms: {
        main: true, // Inicializar como true
        map: true,  // Inicializar como true
        extraData: true, // Inicializar como true
      },
      editedItem: {
        id: null,
        country: null,
        control_label: "",
        company_id: null,
        storage_device_type_id: null,
        storageDeviceType: null,
        primaryLocation: null,
        location: null,
        logo: null,
        latitude: "",
        longitude: "",
        productive: true,
        locationOwner: null,
        mediumHeight: null,
        minHeight: null,
        maxHeight: null,
        mediumTemperature: 0,
        area: 0,
        gln: "",
        measurementUnit: null,
        measurement_unit_id: null,
        active: true,
        comercialSite: "",
        description: "",
        description_text: "",
        personInChargeIds: [],
        updateDocuments: false,
        updateBadges: false,
        updateLogo: false,
        isVirtual: false,
        extraData: [],
        producers: [],
        storageDeviceId: null,
        carData: [],
        socialMedia: {
          video: "",
          facebook: "",
          instagram: "",
          twitter: "",
        },
        images: [],
        badgesFiles: [],
        badges: [],
        updateVersion: false,
        classificationTypes: [],
      },
      isReady: false,
      integrations: {
        BRAINAG: null,
        WALLET: null,
      },
      heightValidation: {
        show: false,
        invalid: false,
        errorGroups: {}
      },
      configLocations: {
        minHeight: null,
        maxHeight: null
      },
    }),

    async created() {
      const CONFIG = JSON.parse(localStorage.getItem("config"))

      this.configLocations = CONFIG && CONFIG.company && CONFIG.company.locations 
          ? CONFIG.company.locations
          : {
            minHeight: null,
            maxHeight: null
          };

      this.tabs = this.action === "show" ? 0 : 1

      if (this.action !== "show") await this.fetchAllProducer();

      this.isVersion = JSON.parse(localStorage.getItem("storage:isVersion")) === 1 ;

      this.integrations.WALLET = _.find(PROFILE.integrationsCompany, {
        integrationCompanyType: "WALLET",
      })

      this.integrations.BRAINAG = _.find(PROFILE.integrationsCompany, {
        integrationCompanyType: "BRAINAG",
      })

      await this.fetchStorageTypesByCompanyAccess([this.$toast])

      await this.fetchListCountry()

      this.$set(this.editedItem, "personsInCharge", [PROFILE]);
      this.$set(this.editedItem, "personInChargeIds", [PROFILE.id]);

      if (this.action !== "new") {
        if (!this.uuid) {
          await this.$router.push({ name: "storage" })
          return;
        }

        this.editedIndex = localStorage.getItem("storage_editOrSave")

        this.editedItem = await this.fetchStorageById([this.uuid, this.$toast])

        this.editedItem.updateVersion = this.isVersion;

        // await this.fetchPrimaryLocations([
        //   {
        //     storageDeviceTypeIds: [this.editedItem.storageDeviceType.id],
        //     isActive: true,
        //   },
        //   this.$toast,
        // ]).finally(() => {
        //   console.log(this.locations)
        // })

        if (this.editedItem.images && this.editedItem.images.length > 0) {
          this.$set(
            this.editedItem,
            "images",
            _.map(this.editedItem.images, d => {
              return { url: d.s3_url, ...d }
            })
          )
        } else this.editedItem.images = []

        if (this.editedItem.badges && this.editedItem.badges.length > 0) {
          this.$set(
            this.editedItem,
            "badgesFiles",
            _.map(this.editedItem.badges, d => {
              return { initialized: true, url: d.s3_url, ...d }
            })
          )
          this.$set(
            this.editedItem,
            "badges",
            _.map(this.editedItem.badges, d => {
              return { initialized: true, description_text: this.translate(d.description), ...d }
            })
          )
        } else {
          this.editedItem.badgesFiles = []
          this.editedItem.badges = []
        }

        if (this.editedItem.logo && this.editedItem.logo.s3_url) {
          this.$set(this.editedItem, "logo", {
            url: this.editedItem.logo.s3_url,
            ...this.editedItem.logo,
          })
        } else {
          this.$set(this.editedItem, "logo", null)
        }

        this.editedItem.storageDeviceType = this.editedItem.storageDeviceType
          ? this.storageDeviceTypes.find(
              x =>
                x.storage_device_type_id ===
                this.editedItem.storageDeviceType.id
            )
          : null

        if (this.action === "edit" && this.editedItem.description) {
          this.editedItem.description_text = this.translate(
            this.editedItem.description
          )
        }

        this.turfDefaultUnit = _.find(this.allUnits, { code: "ha" })
      }

      // Apply configuration values to model if they're null
      this.applyHeightConfigValues();
      
      // Validate height values against configuration
      this.validateHeightAgainstConfig();

      this.isReady = true
      this.loading = false
    },

    computed: {
      ...mapGetters({
        storageDeviceTypes: "storage_type/storageDeviceTypesByCompanyAccess",
        allUnits: "units/allObjectUnits",
        languageStructure: "general/languageStructure",
        locations: "locations/locationsList",
      }),
      itemColor: {
        get() {
          return this.$store.getters["general/color"]
        },
        set(val) {
          this.$store.commit("general/SET_COLOR", val)
        },
      },
      valid() {
        console.log('valid', this.forms.main, this.forms.extraData, this.heightValidation.invalid)
        return this.forms.main && this.forms.extraData && !this.heightValidation.invalid;
      },
    },

    methods: {
      ...mapActions({
        fetchStorageById: "storage/fetchStorageById",
        saveStorage: "storage/saveStorage",
        fetchStorageTypesByCompanyAccess:
          "storage_type/fetchStorageTypesByCompanyAccess",
        fetchPrimaryLocations: "locations/fetchFilteredLocations",
        fetchListCountry: "general/fetchListCountry",
        fetchListStorage: "authority/getAuthoritiesStorageDevices",
        fetchAllProducer: "producer/fetchAllProducer",
        fetchAreaRange: "ui/fetchSecondaryRange"
      }),
      // Check permission
      check(permissions) {
        return PermissionService.check(permissions)
      },
      // Check permission Admin
      checkAdmin() {
        return PermissionService.checkAdmin()
      },
      translate(key, uppercase) {
        return LanguageService.getKey3(key)
      },
      updateModel(e) {
        this.$set(this, "editedItem", e);
        this.$nextTick(() => this.validateHeightAgainstConfig());
      },
      getFileExtension(url, item) {
        if (url.includes("s3-accelerate.amazonaws.com")) {
          return item.file_extension
        } else {
          let lastSegment = url.split("/").pop()
          let matches = lastSegment.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)
          if (matches && matches.length > 1) {
            return matches[1]
          } else {
            return "jpg"
          }
        }
      },
      scapeUrl(url) {
        let partes = url.split("/")
        let basePath = partes.slice(0, 4).join("/") // 'https://agtracestorage.s3-accelerate.amazonaws.com/abrapa'
        let resourcePath = partes.slice(4).join("/") // '$2a$10$Mf/mc2aX77/W.We3fH.T0OTLhmOZ7WzH.5Ur74/hsWtS3q5CZoEoG'
        resourcePath = encodeURIComponent(resourcePath)
        return `${basePath}/${resourcePath}`
      },
      mapDataReload(){
        this.fetchListStorage([{
          userId: PROFILE.id,
          companyId: PROFILE.company_id,
          isActive: true
        }, this.$toast])
      },
      validateAllTabs() {
        let isValid = true;
        
        // Solo validar si los componentes existen
        if (this.$refs.tabMainLocation) {
          isValid = this.$refs.tabMainLocation.validate() && isValid;
        }
        
        if (this.$refs.tabMapLocation) {
          isValid = this.$refs.tabMapLocation.validate() && isValid;
        }
        
        if (this.$refs.tabExtraLocation) {
          isValid = this.$refs.tabExtraLocation.validateAllHeights() && isValid;
        }
        
        return isValid;
      },
      
      async save() {
        // Validate height values before proceeding
        this.validateHeightAgainstConfig();
        if (this.heightValidation.invalid) {
          this.$toast.error(this.$t("storage.validation.fixHeightErrors"));
          return;
        }
        
        // Validate all tabs before saving
        // const isValid = this.validateAllTabs();
        
        // if (!isValid) {
        //   this.$toast.error(this.$t("common.notification.validationError"));
        //   return;
        // }
        
        this.loading = true;

        let item = Object.assign({}, this.editedItem);
        
        // Normalize numeric values for saving
        const numericFields = ['mediumHeight', 'minHeight', 'maxHeight', 'mediumTemperature', 'area'];
        numericFields.forEach(field => {
          if (item[field] !== undefined && item[field] !== null) {
            item[field] = NumberFormatService.normalizeForServer(item[field]);
          }
        });

        let geobufData = null
        if (
          this.IsJsonString(this.editedItem.location) &&
          this.editedItem.location &&
          this.editedItem.location !== "null"
        ) {
          // Compress Geojson with Geobuf
          this.editedItem.location = JSON.parse(this.editedItem.location)
          this.editedItem.location.features = _.map(
            this.editedItem.location.features,
            feature => {
              feature.id = null
              return feature
            }
          )
          geobufData = geobuf.encode(this.editedItem.location, new Pbf())
          item.locationFormat = "GEOBUF"
          item.location = null
        } else {
          let locationClone = null
          if (this.editedItem.locationFormat === "GEOBUF") {
            locationClone = await MapService.singleLocationFormat(
              this.editedItem
            )
            this.$set(this, "editedItem", locationClone)
            geobufData = geobuf.encode(
              JSON.parse(this.editedItem.location),
              new Pbf()
            )
            item.location = null
          }
        }

        item.storageDeviceType = {
          id: item.storageDeviceType.storage_device_type_id,
        }
        item.measurementUnit = this.turfDefaultUnit

        if (item.id === null) item.company = { id: PROFILE.company_id }

        item.description = LanguageService.setKey3(
          item.description_text,
          item.description,
          !item.id ? this.languageStructure : null
        )

        item.color = this.itemColor

        item.personInChargeIds = _.map(item.personsInCharge, p => p.id)
        item.producers = _.map(item.producers, p => p.id)

        const document = {
          documentAuthority: { id: 1 },
          documentType: { id: 1 },
          documentDescription: { id: 1 },
          company: { id: item.company.id },
        }

        let logo = null
        if (item.logo && item.logo.url && item.updateLogo) {
          let url = item.logo.url

          if (!url.startsWith("blob:")) {
            url = this.scapeUrl(url)
          }

          await fetch(url)
            .then(r => r.blob())
            .then(blobFile => {
              let extension = this.getFileExtension(url, item.logo)
              logo = new File([blobFile], "logo", {
                type: `image/${extension}`,
              })
            })
        }

        if (item.images && item.images.length > 0 && item.updateDocuments) {
          for (let i = 0; i < item.images.length; i++) {
            let url = item.images[i].url

            if (!url.startsWith("blob:")) {
              url = this.scapeUrl(url)
            }

            try {
              let response = await fetch(url)
              let data = await response.blob()
              let extension = this.getFileExtension(url, item.images[i])
              let file = new File([data], `image_${i}.${extension}`, {
                type: `image/${extension}`,
              })
              item.images[i] = file
            } catch (error) {
              console.error("Error al obtener la imagen:", error)
            }
          }
        }

        if (
          item.badgesFiles &&
          item.badgesFiles.length > 0 &&
          item.updateBadges
        ) {
          for (let i = 0; i < item.badgesFiles.length; i++) {
            delete item.badges[i]['classification'];

            let url = item.badgesFiles[i].url

            if (!url.startsWith("blob:")) {
              url = this.scapeUrl(url)
            }

            try {
              let response = await fetch(url)
              let data = await response.blob()
              let extension = this.getFileExtension(url, item.badgesFiles[i])
              let file = new File([data], `image_${i}.${extension}`, {
                type: `image/${extension}`,
              })
              item.badgesFiles[i] = file
              item.badges[i].file_associate_code = file.name
              item.badges[i].size = file.size
              item.badges[i].file_extension = extension
              item.badges[i].description = LanguajeService.setKey3(
                item.badges[i].description_text,
                item.badges[i].description,
                !item.badges[i].id ? this.languageStructure : null
              )
            } catch (error) {
              console.error("Error getting the badge:", error)
            }
          }
        }

        // normalize address (send only names)
        item = {
          ...item,
          country: getByProperty(item.country, 'name'),
          state: getByProperty(item.state, 'name'),
          city: getByProperty(item.city, 'name')
        }

        console.log(item)

        await this.saveStorage([
          item,
          this.editedIndex,
          document,
          logo,
          document,
          item.images,
          item.badgesFiles,
          geobufData,
          this.$toast,
        ])
          .then(async (res) => {
            if(res)
              await this.fetchAreaRange().finally(() => {
                this.back();
              });
          })
          .finally(() => {
            this.mapDataReload()
            this.loading = false
          })
      },
      IsJsonString(json) {
        try {
          JSON.parse(json)
        } catch (e) {
          return false
        }
        return true
      },
      updateImages(e) {
        this.$set(this.editedItem, "images", e)
      },
      updateBadgesFiles(e) {
        this.$set(this.editedItem, "badgesFiles", e)
      },
      updateBadges(e) {
        this.$set(this.editedItem, "badges", e)
      },
      updateLogo(e) {
        this.$set(this.editedItem, "logo", e)
      },
      back() {
        this.clear();
        this.$router.push({ name: "storage" })
      },
      clear() {
        localStorage.removeItem("storageItem")
        localStorage.removeItem("storage_editOrSave")
        localStorage.removeItem("storage:isVersion")
      },
      applyHeightConfigValues() {
        // Only apply config values if they exist and model values are null
        if (this.action !== 'show' && (this.editedItem.minHeight === null || this.editedItem.minHeight === undefined)) {
          if (this.configLocations.minHeight !== null && this.configLocations.minHeight !== undefined) {
            this.$set(this.editedItem, 'minHeight', this.configLocations.minHeight);
          }
        }

        if (this.action !== 'show' && (this.editedItem.maxHeight === null || this.editedItem.maxHeight === undefined)) {
          if (this.configLocations.maxHeight !== null && this.configLocations.maxHeight !== undefined) {
            this.$set(this.editedItem, 'maxHeight', this.configLocations.maxHeight);
          }
        }
      },

      validateHeightAgainstConfig() {
        if (!['new', 'edit'].includes(this.action)) return;
        
        this.heightValidation.invalid = false;
        this.heightValidation.show = false;
        this.heightValidation.errorGroups = {
          7: {
            title: this.$t('storage.validation.extraDataTab'),
            icon: 'mdi-database',
            errors: []
          }
        };
        
        if (!this.configLocations.minHeight && !this.configLocations.maxHeight) return;

        const extraDataGroup = this.heightValidation.errorGroups[7];

        // Validaciones contra la configuración
        if (this.configLocations.minHeight && this.editedItem.minHeight !== null && 
            NumberFormatService.parseLocalizedNumber(this.editedItem.minHeight) < 
            NumberFormatService.parseLocalizedNumber(this.configLocations.minHeight)) {
          extraDataGroup.errors.push(this.$t('storage.rules.belowConfigMin', {
            value: this.configLocations.minHeight
          }));
        }

        if (this.configLocations.maxHeight && this.editedItem.maxHeight !== null && 
            NumberFormatService.parseLocalizedNumber(this.editedItem.maxHeight) > 
            NumberFormatService.parseLocalizedNumber(this.configLocations.maxHeight)) {
          extraDataGroup.errors.push(this.$t('storage.rules.exceedsConfigMax', {
            value: this.configLocations.maxHeight
          }));
        }

        // Validaciones de consistencia
        if (this.editedItem.minHeight !== null && this.editedItem.maxHeight !== null &&
            NumberFormatService.parseLocalizedNumber(this.editedItem.minHeight) > 
            NumberFormatService.parseLocalizedNumber(this.editedItem.maxHeight)) {
          extraDataGroup.errors.push(this.$t('storage.rules.minShouldBeLessOrEqual'));
        }

        // Validaciones de altura media
        if (this.editedItem.mediumHeight !== null) {
          const mediumHeight = NumberFormatService.parseLocalizedNumber(this.editedItem.mediumHeight);
          const minHeight = this.editedItem.minHeight ? NumberFormatService.parseLocalizedNumber(this.editedItem.minHeight) : null;
          const maxHeight = this.editedItem.maxHeight ? NumberFormatService.parseLocalizedNumber(this.editedItem.maxHeight) : null;

          if (minHeight !== null && mediumHeight < minHeight) {
            extraDataGroup.errors.push(this.$t('storage.rules.belowMinHeight'));
          }
          
          if (maxHeight !== null && mediumHeight > maxHeight) {
            extraDataGroup.errors.push(this.$t('storage.rules.aboveMaxHeight'));
          }
        }

        // Verificar si hay errores
        const hasErrors = Object.values(this.heightValidation.errorGroups)
          .some(group => group.errors.length > 0);

        if (hasErrors) {
          this.heightValidation.invalid = true;
          this.heightValidation.show = true;
        }
      },

      goToTab(tabIndex) {
        this.tabs = tabIndex;
      },
    },

    watch: {
      'editedItem.minHeight': function() {
        this.validateHeightAgainstConfig();
      },
      'editedItem.maxHeight': function() {
        this.validateHeightAgainstConfig();
      },
      'editedItem.mediumHeight': function() {
        this.validateHeightAgainstConfig();
      },
      tabs: function(newVal) {
        // When switching to the extras tab (7), apply height config values
        if (newVal === 7) {
          this.applyHeightConfigValues();
          this.validateHeightAgainstConfig();
        }
      }
    },

    destroyed() {
      this.integrations = {
        BRAINAG: null,
        WALLET: null,
      }

      this.clear();
    },
  }
</script>

<style lang="scss" scoped>
  :deep(.v-tabs-bar) {
    background-color: transparent;
    margin: 0 15px;
    border-radius: 0.3em;
  }

  :deep(.v-slide-group__wrapper) {
    display: grid;
    justify-content: start;
    border-radius: 0.3em;
    margin: 0;
  }

  :deep(.theme--light.v-tabs:not(.v-tabs--vertical) .v-tab) {
    margin: 0 !important;
    padding: 0 10px !important;
    font-size: 14px;
  }

  .v-tab--active {
    color: var(--v-t-bw-primary-base) !important;
    background-color:var(--v-primary-base) !important;
  }

  .validation-alert {
    border-left: 4px solid var(--v-error-base) !important;
  }

  .validation-group {
    background: rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    padding: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .group-title {
    font-weight: 500;
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.7);
  }

  .validation-errors {
    margin: 8px 0;
  }

  .validation-error {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .error-message {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.3;
  }
</style>