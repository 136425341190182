<template>
  <v-layout class="d-block">
    <v-row class="mb-12">
      <v-col cols="6">
        <v-card outlined class="fill-height card-hover">
          <v-app-bar dense elevation="0" flat>
            <h3 class="font-weight-bold">{{ $t("locations.titles.mainInformation") }}</h3>
            <v-spacer></v-spacer>
            <v-img class="mx-4" :src="require('@/assets/icons/firstline.svg')" contain height="25px" max-height="25px"
                   width="25px" max-width="25px" />
          </v-app-bar>
          <v-card-text>
            <v-row v-if="action === 'show'">
              <v-col cols="12" class="ma-0 pa-0 my-2 px-4">
                <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("storage.fields.name") }}:</h2>
                    {{ item.control_label || "-" }}
                  </span>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.storage_type") }}:</h2>
                    {{ item.storageDeviceType ? item.storageDeviceType.storage_type_name : "-" }}
                  </span>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0 my-2 px-4">
                <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.name") }}:</h2>
                    {{ item.primaryLocation.name || "-" }}
                  </span>
              </v-col>
            </v-row>
            <v-form v-if="['new', 'edit'].includes(action)" ref="formMain" v-model="valid.main">
              <v-row>
                <v-col cols="12" class="ma-0 pa-0 my-2 px-4">
                  <span
                    style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <v-text-field
                      v-model="item.name"
                      :counter="max"
                      :rules="requireAndMaxRules(max)"
                      class="required"
                      color="primary"
                      maxlength="max"
                      onkeypress="return (event.charCode != 34)"
                      required
                      dense
                      outlined
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                      :disabled="isVersion"
                    >
                      <template v-slot:label>
                        {{ $t("storage.fields.name") }}
                        <strong class="red--text">*</strong>
                      </template>
                    </v-text-field>
                  </span>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0 my-2 px-4">
                  <span
                    style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-combobox
                        v-model="item.storageDeviceType"
                        :clearable="true"
                        :items="storageDeviceTypes"
                        :label="$t('locations.fields.storage_type')"
                        :rules="requireRules"
                        class="required"
                        color="primary"
                        item-text="storage_type_name"
                        persistent-hint
                        small-chips
                        dense
                        outlined
                        item-color="primary"
                        @change="changeSDT({
                          storageDeviceTypeIds: item.storageDeviceType,
                          isActive: true,
                          ids: []
                        })"
                        :disabled="isVersion"
                      >
                        <template v-slot:label>
                          {{ $t("locations.fields.storage_type") }}
                          <strong class="red--text">*</strong>
                        </template>
                      </v-combobox>
                    </span>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0 my-2 px-4">
                  <span
                    style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <v-autocomplete
                      v-model="item.primaryLocation"
                      :clearable="true"
                      :disabled="!item.storageDeviceType || isVersion"
                      :items="locations"
                      :no-data-text="$t('common.fields.noDataAvailable')"
                      :rules="requireRules"
                      class="required"
                      color="primary"
                      item-text="name"
                      item-id="locationId"
                      return-object
                      small-chips
                      dense
                      outlined
                      item-color="primary"
                      :loading="loadings.primary"
                      @click:clear="clearLocations"
                      @keydown.enter="
                        fetchLocations({
                        storageDeviceTypeIds: item.storageDeviceType,
                        isActive: true,
                        stringFilter: $event.target.value || ''
                      })"
                    >
                      <template v-slot:label>
                        {{ $t("storage.fields.location") }}
                        <strong class="red--text">*</strong>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-chip v-if="item" small>
                          {{ item.name }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </span>
                </v-col>
                <!-- <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <v-text-field
                      v-model="item.document_key"
                      :rules="requireRules"
                      class="required"
                      color="primary"
                      onkeypress="return (event.charCode != 34)"
                      required
                      dense
                      outlined
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                    >
                      <template v-slot:label>
                        {{ $t("company.fields.identificationType") }}
                        <strong class="red--text">*</strong>
                      </template>
                    </v-text-field>
                  </span>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <v-text-field
                      v-model="item.document_value"
                      :rules="requireRules"
                      class="required"
                      color="primary"
                      onkeypress="return (event.charCode != 34)"
                      required
                      dense
                      outlined
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                    >
                      <template v-slot:label>
                        {{ $t("company.fields.identificationValue") }}
                        <strong class="red--text">*</strong>
                      </template>
                    </v-text-field>
                  </span>
                </v-col> -->
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined class="fill-height card-hover">
          <v-app-bar dense elevation="0" flat>
            <h3 class="font-weight-bold">{{ $t("locations.titles.secondaryInformation") }}</h3>
            <v-spacer></v-spacer>
            <v-img class="mx-4" :src="require('@/assets/icons/firstline.svg')" contain height="25px" max-height="25px"
                   width="25px" max-width="25px" />
          </v-app-bar>
          <v-card-text>
            <v-row v-if="action === 'show'">
              <v-col cols="6">
                <div class="card__items">
                  <!-- <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.telephone") }}:</h2>
                    {{ item.telephone || '-' }}
                  </span>
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.email") }}:</h2>
                    {{ item.email || '-' }}
                  </span> -->
                  <span
                    style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.gln") }}:</h2>
                    {{ item.gln || "-" }}
                  </span>
                  <span
                    style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.commercialSite") }}:</h2>
                    {{ item.comercialSite || "-" }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12">
                <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                  <h2 class="mr-2 font-weight-bold">{{ $t("authority.label") }}:</h2>
                  {{ responsavel }}
                </span>
              </v-col>
              <v-col cols="12">
                <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                  <h2 class="mr-2 font-weight-bold">{{ $t("producer.label") }}:</h2>
                  {{ computedProducers }}
                </span>
              </v-col>
            </v-row>
            <v-form v-if="['new', 'edit'].includes(action)" ref="formSecondary" v-model="valid.secondary">
              <v-row>
                <v-col cols="6">
                  <div class="card__items">
                    <!-- <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-text-field
                        v-model="item.telephone"
                        :label="$t('locations.fields.telephone')"
                        color="primary"
                        dense
                        outlined
                        onkeypress="return (event.charCode != 34)"
                        v-on:keyup.86="replace"
                        v-on:keyup.ctrl.86="replace"
                      >
                      </v-text-field>
                    </span>
                    <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-text-field
                        v-model="item.email"
                        :label="$t('locations.fields.email')"
                        color="primary"
                        dense
                        outlined
                        onkeypress="return (event.charCode != 34)"
                        v-on:keyup.86="replace"
                        v-on:keyup.ctrl.86="replace"
                      />
                    </span> -->
                    <span
                      style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-text-field
                        v-model="item.gln"
                        :counter="maxGLN"
                        :rules="
                          existingElementGlnSecondary(maxGLN, messageValidGln)
                        "
                        dense
                        outlined
                        color="primary"
                        :maxlength="maxGLN"
                        onkeypress="return (event.charCode != 34)"
                        @input="checkGln()"
                        v-on:keyup.86="replace"
                        v-on:keyup.ctrl.86="replace"
                        :disabled="isVersion"
                      >
                        <template v-slot:label>
                          {{ $t("storage.fields.gln") }}
                          <strong class="red--text">*</strong>
                        </template>
                      </v-text-field>
                    </span>
                    <span
                      style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-text-field
                        dense
                        outlined
                        v-model="item.comercialSite"
                        :label="$t('locations.fields.commercialSite')"
                        color="primary"
                      >
                      </v-text-field>
                    </span>
                  </div>
                </v-col>
                <v-col cols="12">
                  <span
                    style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <v-combobox
                      v-model="item.personsInCharge"
                      :clearable="true"
                      :items="authorities"
                      :no-data-text="$t('common.fields.noDataAvailable')"
                      :rules="selectRule"
                      class="required ma-0 pa-0"
                      color="primary"
                      item-text="name"
                      item-value="id"
                      multiple
                      persistent-hint
                      return-object
                      small-chips
                      dense
                      outlined
                      item-color="primary"
                      :disabled="!checkAdmin()"
                    >
                      <template v-slot:label>
                        {{ $t("locations.fields.personInCharge") }}
                        <strong class="red--text">*</strong>
                      </template>
                      <template v-slot:selection="{ item:user, index }">
                        <v-chip small v-if="index === 0">
                          <span>{{ user.name }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text caption"
                        >(+{{
                            item.personsInCharge.length - 1
                          }}
                          {{ $t("common.titles.others") }})</span
                        >
                      </template>
                    </v-combobox>
                  </span>
                  <span
                    style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <v-combobox
                      v-model="item.producers"
                      :clearable="true"
                      :items="producers"
                      :no-data-text="$t('common.fields.noDataAvailable')"
                      class="required ma-0 pa-0"
                      color="primary"
                      item-text="name"
                      item-value="id"
                      multiple
                      persistent-hint
                      small-chips
                      dense
                      outlined
                      item-color="primary"
                      :disabled="isVersion"
                    >
                      <template v-slot:label>
                        {{ $t("locations.fields.producer") }}
                      </template>
                      <template v-slot:selection="{ item:producer, index }">
                        <v-chip small v-if="index === 0">
                          <span>{{ producer.name }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text caption"
                        >(+{{
                            item.producers.length - 1
                          }}
                          {{ $t("common.titles.others") }})</span
                        >
                      </template>
                    </v-combobox>
                  </span>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined class="fill-height card-hover">
          <v-app-bar dense elevation="0" flat>
            <h3 class="font-weight-bold">{{ $t("storage.titles.car") }}</h3>
            <v-spacer></v-spacer>
            <v-img class="mx-4" :src="require('@/assets/icons/firstline.svg')" contain height="25px" max-height="25px"
                   width="25px" max-width="25px" />
          </v-app-bar>
          <v-card-text>
            <span style="display: block; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
              <v-form v-if="['new', 'edit'].includes(action)" ref="formCar" v-model="valid.car">
                <v-text-field
                  v-model="carData"
                  v-mask="'AA-#######-NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN'"
                  :disabled="loadingVerifyCar"
                  placeholder="AA-#######-NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"
                  append-icon="mdi-magnify"
                  color="primary"
                  dense
                  flat
                  outlined
                  onkeypress="return (event.charCode != 34)"
                  :rules="carRules"
                  :hide-details="valid.car"
                  @input="checkValidCar($event)"
                  class="mb-2"
                >
                  <template v-slot:append>
                    <div class="pb-2">
                      <v-btn
                        v-if="!loadingVerifyCar"
                        :disabled="!valid.car || carInUse"
                        icon
                        small
                        text
                        @click="addCar"
                      >
                        <v-icon> mdi-plus </v-icon>
                      </v-btn>
                      <v-progress-circular
                        v-else
                        indeterminate
                        color="primary"
                        size="18"
                        width="3"
                        class="mx-2 my-1"
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-text-field>

                <v-alert
                  v-if="carInUse"
                  dense
                  text
                  type="error"
                  border="left"
                  class="mb-4"
                  dismissible
                >
                  <span v-html="$t('storage.notification.carInUse')"></span>
                </v-alert>
              </v-form>

              <CarListComponent :show-actions="['new','edit'].includes(action)" :cars="item.carData" @remove-car="removeCarFromList" />
            </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined class="fill-height card-hover">
          <v-app-bar dense elevation="0" flat>
            <h3 class="font-weight-bold">{{ $t("locations.titles.addressInformation") }}</h3>
            <v-spacer></v-spacer>
            <v-img class="mx-4" :src="require('@/assets/icons/firstline.svg')" contain height="25px" max-height="25px"
                   width="25px" max-width="25px" />
          </v-app-bar>
          <v-card-text>
            <v-row v-if="action === 'show'">
              <v-col cols="6">
                <div class="card__items">
                  <span
                    style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.address1") }}:</h2>
                    {{ item.address1 || "-" }}
                  </span>
                  <span
                    style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.address2") }}:</h2>
                    {{ item.address2 || "-" }}
                  </span>
                  <span
                    style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.state") }}:</h2>
                    {{ item.state || "-" }}
                  </span>
                  <span
                    style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.city") }}:</h2>
                    {{ item.city || "-" }}
                  </span>
                  <span
                    style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.country") }}:</h2>
                    {{ item.country || "-" }}
                  </span>
                  <span
                    style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.zipCode") }}:</h2>
                    {{ item.zipCode || "-" }}
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-form v-if="['new', 'edit'].includes(action)" ref="formAddress" v-model="valid.address">
              <address-form-component
                v-if="['new', 'edit'].includes(action) && isReady"
                :fieldProps="fieldPropsAddress"
                :item="item"
              />
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card outlined class="fill-height card-hover">
          <v-app-bar dense elevation="0" flat>
            <h3 class="font-weight-bold">{{ $t("locations.titles.extraInformation") }}</h3>
            <v-spacer></v-spacer>
            <v-img class="mx-4" :src="require('@/assets/icons/firstline.svg')" contain height="25px" max-height="25px"
                   width="25px" max-width="25px" />
          </v-app-bar>
          <v-card-text>
            <v-row v-if="action === 'show'">
              <v-col cols="12">
                <div class="card__items">
                  <span
                    style="display: block; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="font-weight-bold">{{ $t("locations.fields.description") }}:</h2>
                    <div style="height: 100px; overflow: auto">
                      <read-more-component
                        :classes="'mb-0'"
                        :text="translate(item.description) ||'-'"
                        :max-chars="300"
                        :font-size="14"
                      />
                    </div>
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-form v-if="['new', 'edit'].includes(action)" ref="formExtras" v-model="valid.extras">
              <v-row>
                <v-col cols="12">
                  <div class="card__items">
                    <span
                      style="
                        display: flex;
                        border-radius: 0.2em;
                        background-color: rgb(250, 250, 250);
                        padding: 20px;
                      "
                    >
                    <v-textarea
                      v-model="item.description_text"
                      :auto-grow="false"
                      :clearable="false"
                      :counter="3000"
                      :label="$t('locations.fields.description')"
                      :no-resize="true"
                      :row-height="40"
                      :rows="7"
                      :rules="lengthRules(3000).concat(limitEnterKeyPress)"
                      color="primary"
                      onkeypress="return (event.charCode != 34)"
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                      dense
                      outlined
                    />
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
  import { mapGetters, mapActions } from "vuex"
  import LanguageService from "@/services/LanguajeService"
  import StorageService from "@/services/StorageService"
  import PermissionService from "@/services/PermisionService"
  import ReadMoreComponent from "@/components/ReadMoreComponent.vue"
  import AddressFormComponent from "@/module/configuration/location/components/common/AddressFormComponent.vue"
  import CarListComponent from "@/module/configuration/storage/components/common/CarListComponent.vue"
  import i18n from "@/plugins/i18n"

  export default {
    components: { CarListComponent, AddressFormComponent, ReadMoreComponent },
    name: "TabMainLocation",
    props: {
      item: {
        type: Object,
        required: true,
      },
      action: {
        type: String,
        required: false,
        default: "show",
      },
      isReady: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data: () => ({
      isVersion: false,
      editedIndex: null,
      loadings: {
        primary: false,
      },
      valid: {
        main: false,
        secondary: false,
        address: false,
        car: false,
        extras: false,
      },
      writingTime: null,
      description: "",
      max: 40,
      maxGLN: 18,
      messageValidGln: "",
      isValid: false,
      fieldPropsAddress: {
        address1: { props: { color: "secondary", outlined: true, dense: true } },
        address2: { props: { color: "secondary", outlined: true, dense: true } },
        country: {
          props: {
            color: "secondary",
            outlined: true,
            dense: true,
            itemColor: "secondary",
            returnObject: true,
          },
        },
        state: {
          props: {
            color: "secondary",
            outlined: true,
            dense: true,
            itemColor: "secondary",
            returnObject: true,
          },
        },
        city: {
          props: {
            color: "secondary",
            outlined: true,
            dense: true,
            itemColor: "secondary",
            returnObject: true,
          },
        },
        zipCode: { props: { color: "secondary", outlined: true, dense: true } },
      },
      carData: null,
      carInUse: false,
      carRules: [
        v => !v || /^[A-Z]{2}-\d{7}-[A-Z0-9]{32,}$/.test(v) || i18n.t('common.rules.invalidFormat')
      ],
      loadingVerifyCar: false,
    }),

    async created() {
      this.isVersion = JSON.parse(localStorage.getItem("storage:isVersion")) === 1
      this.editedIndex = localStorage.getItem("storage_editOrSave");

      // if (this.item.storageDeviceType) {
      //   this.locations = this.item.primaryLocation
      // }

      await this.fetchPrimaryLocations([
        {
          ids: this.item.primaryLocation ? [this.item.primaryLocation.locationId] : [],
          onlyCurrentVersion: true,
        },
        this.$toast,
      ]).finally(() => {
        this.$set(this.loadings, "primary", false)
      })
    },

    watch: {
      valid: {
        handler() {
          this.$emit("update:validation", this.valid.main && this.valid.secondary && this.valid.address && this.valid.extras && this.valid.car)
        },
        deep: true,
      },
    },

    computed: {
      ...mapGetters({
        locations: "locations/locationsList",
        producers: "producer/GET_PRODUCERS",
        storageDeviceTypes:
          "storage_type/storageDeviceTypesByCompanyAccess",
        requireRules: "general/requireRules",
        requireAndMaxRules: "general/requireAndMaxRules",
        existingElementGln: "general/existingElementGln",
        authorities: "authority/authoritiesForLocations",
        lengthRules: "general/lengthRules",
        selectRule: "general/selectRule",
        limitEnterKeyPress: "general/limitEnterKeyPress",
        existingElementGlnSecondary: "general/existingElementGlnSecondary",
        countryList: "general/countryList",
      }),
      responsavel() {
        return (this.item.personsInCharge && (this.item.personsInCharge.length > 0)) ? _.map(this.item.personsInCharge, p => p.name).join(", ") : "-"
      },
      computedProducers() {
        return (this.item.producers && (this.item.producers.length > 0)) ? _.map(this.item.producers, p => p.name).join(", ") : "-"
      },
    },

    methods: {
      ...mapActions({
        fetchPrimaryLocations: "locations/fetchFilteredLocations",
        verifyCar: "storage/verifyCar",
      }),
      checkAdmin() {
        return PermissionService.checkAdmin()
      },
      async checkValidCar(e) {
        this.carData = this.carData.toUpperCase();

        setTimeout(async () => {
          if (this.valid.car && (e && e !== "" && e.length === 43)) {
            await this.checkCar(e);
          }
        }, 0)
      },
      async checkCar(e) {
        this.loadingVerifyCar = true;
        this.carInUse = false;

        try {
          const { data } = await StorageService.verifyCar({
            car: e,
            isEditing: !!this.editedIndex,
          });

          if (data.valid) {
            this.carInUse = false;
          }
        } catch (error) {
          if (error.response.data.status === 406 && error.response.data.message === 'CAR_IN_USE_BY_YOUR_COMPANY') {
            this.carInUse = true;
          } else {
            console.error("Error verifying CAR:", error);
          }
        } finally {
          this.loadingVerifyCar = false;
        }
      },
      addCar() {
        if (this.carData && !this.carInUse) {
          this.item.carData.unshift(this.carData);
          this.carData = '';
        }
      },
      removeCarFromList(index) {
        this.item.carData.splice(index, 1)
      },
      async checkGln() {
        this.messageValidGln = ""
        if (this.writingTime !== null) clearTimeout(this.writingTime)
        this.writingTime = setTimeout(async x => {
          try {
            await StorageService.checkGln(this.item.gln).then(() => {
              this.messageValidGln = ""
            })
          } catch (error) {
            if (error.response.status === 406) {
              this.messageValidGln = error.response.data.message
            }
          }
        }, 1000)
      },
      async changeSDT(filters) {
        this.item.primaryLocation = null;
        await this.fetchLocations(filters)
      },
      async fetchLocations(filters) {
        this.$set(this.loadings, "primary", true)

        if (this.item.storageDeviceType) {
          if (typeof this.item.storageDeviceType === "object")
            filters.storageDeviceTypeIds = [this.item.storageDeviceType.storage_device_type_id]
        } else if (filters.storageDeviceTypeIds) {
          if (typeof filters.storageDeviceTypeIds === "object")
            filters.storageDeviceTypeIds = [filters.storageDeviceTypeIds.storage_device_type_id]
        }

        if (this.item.primaryLocation && this.action === "edit") {
          filters.ids = [this.item.primaryLocation.locationId]
        }

        await this.fetchPrimaryLocations([
          filters,
          this.$toast,
        ]).finally(() => {
          this.$set(this.loadings, "primary", false)
        })
      },

      clearLocations() {
        this.item.primaryLocation = null
      },
      translate(key, uppercase) {
        return LanguageService.getKey3(key)
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll("\"", "")
      },
    },
  }
</script>


<style scoped>
  .fill-icon__color {
    filter: invert(20%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(400%) contrast(88%);
  }

  .card-hover {
    box-shadow: none;
    transition: all 200ms ease-in-out;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
  }

  .card__items {
    display: grid;
    grid-template-columns: 100% 100%;
    gap: 14px
  }
</style>