<template>
  <v-layout class="d-block">
    <v-row>
      <v-col cols="12">
        <map-box
          ref="mapLocations"
          :actionColor="(!isVersion && ['new', 'edit'].includes(action))"
          :actionStyle="!isVersion"
          :confStyle="'satellite-v9'"
          :displayDrawControlsDefault="false"
          :drawControl="!isVersion"
          :drawControls="['new', 'edit'].includes(action)? controlsEdit : controlsShow"
          :fullScreenControl="false"
          :geolocateControl="true"
          :mapConfiguration="mapConfig"
          :mapHeight="65"
          :navigationControl="true"
          :oneLocation="true"
          :scaleControl="true"
          :searchControl="true"
          v-on:areaChange="setDataFormLocations($event)"
          v-on:createMap="handleMapClick"
          v-on:updateMap="handleMapClick"
          v-on:deleteMap="handleMapClick"
          v-on:colorChange="setColor"
          v-on:loadComplete="initialize"
          v-on:styleLoad="setStyle"
          :layers="layers"
        />
      </v-col>
    </v-row>
    <v-row v-if="action === 'show'" class="mb-12">
      <v-col cols="4" class="ma-0 pa-0 my-2 px-4">
        <span
          style="
            display: flex;
            border-radius: 0.2em;
            background-color: rgb(250, 250, 250);
            padding: 20px;
          "
        >
          <h2 class="mr-2 font-weight-bold">
            {{ $t("storage.fields.area") }}:
          </h2>
          {{ clone.area || 0 }}
        </span>
      </v-col>
      <v-col cols="4" class="ma-0 pa-0 my-2 px-4">
        <span
          style="
            display: flex;
            border-radius: 0.2em;
            background-color: rgb(250, 250, 250);
            padding: 20px;
          "
        >
          <h2 class="mr-2 font-weight-bold">
            {{ $t("storage.fields.latitude") }}:
          </h2>
          {{ clone.latitude || 0 }}
        </span>
      </v-col>
      <v-col cols="4" class="ma-0 pa-0 my-2 px-4">
        <span
          style="
            display: flex;
            border-radius: 0.2em;
            background-color: rgb(250, 250, 250);
            padding: 20px;
          "
        >
          <h2 class="mr-2 font-weight-bold">
            {{ $t("storage.fields.longitude") }}:
          </h2>
          {{ clone.longitude || 0 }}
        </span>
      </v-col>
    </v-row>
    <v-form v-if="['new', 'edit'].includes(action)" ref="form" v-model="valid">
      <v-row class="grey lighten-5 my-2 mx-0 justify-start align-content-center align-center" style="border-radius: .3em">
        <v-col cols="4" class="pa-6">
          <v-currency-field
            :default-value="null"
            :value-as-integer="false"
            v-model="clone.area"
            :allow-negative="false"
            :auto-decimal-mode="false"
            :decimal-length="decimalFormat"
            :label="$t('storage.fields.area')"
            :suffix="areaSuffix"
            class="mb-2 required"
            color="primary"
            locale="en"
            dense
            outlined
            :disabled="isVersion"
          >
          </v-currency-field>
        </v-col>
        <v-col cols="4" class="pa-6">
          <v-text-field
            v-model="clone.latitude"
            color="primary"
            onkeypress="return (event.charCode != 34)"
            dense
            outlined
            :rules="latitudeRules"
            v-on:keyup.86="replace"
            v-on:keyup.ctrl.86="replace"
            :disabled="isVersion"
          >
            <template v-slot:label>
              {{ $t("storage.fields.latitude") }}
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="4" class="pa-6">
          <v-text-field
            v-model="clone.longitude"
            color="primary"
            onkeypress="return (event.charCode != 34)"
            dense
            outlined
            :rules="longitudeRules"
            v-on:keyup.86="replace"
            v-on:keyup.ctrl.86="replace"
            :disabled="isVersion"
          >
            <template v-slot:label>
              {{ $t("storage.fields.longitude") }}
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-layout>
</template>

<script>
  import _ from 'lodash';
  import hexToRgba from "hex-to-rgba"
  import { mapGetters, mapActions } from "vuex"
  import MapBox from "@/components/vueMapbox/MapBox.vue"
  import LanguageService from "@/services/LanguajeService"
  import N_UnitsService from "@/services/N_UnitsService"
  import {
    convertLatitude,
    convertLongitude,
    isValidLatLong,
  } from "../../../../../../../utils/convert-to-DMS-format"
  import * as turf from "@turf/turf"
  import MapService from "@/services/MapService";

  const PROFILE = JSON.parse(localStorage.getItem('profile'));

  export default {
    name: "TabMapLocation",
    components: { MapBox },

    props: {
      item: {
        type: Object,
        required: true,
      },
      action: {
        type: String,
        required: false,
        default: "show",
      },
    },

    data: () => ({
      isVersion: false,
      valid: false,
      clone: {},
      loading: false,
      //MAP
      controlsEdit: {
        polygon: true,
        point: true,
        trash: true,
      },
      controlsShow: {
        polygon: false,
        point: false,
        trash: false,
      },
      turfDefaultUnit: null,
      areaSuffix: "ha",
      layers: [
        { 'id': 'l_locations_clusters', checked: false },
        { 'id': 'l_locations_clusters_count',checked: false },
        { 'id': 'l_locations_names', checked: true},
        { 'id': 'l_locations_up_zoom', checked: false },
        { 'id': 'l_locations_down_zoom', checked: false },
        { 'id': 'l_locations_center_points', checked: false },
        { 'id': 'l_locations_center_points_icon', checked: false },
        { 'id': 'l_locations_clusters_points', checked: false },
        { 'id': 'l_locations_clusters_points_icon', checked: false },
        { 'id': 'l_locations_all', checked: true },
        { 'id': 'l_locations_fill_all', checked: true },

        { 'id': 'l_storages_clusters', checked: false },
        { 'id': 'l_storages_clusters_count',checked: false },
        { 'id': 'l_storages_names', checked: true},
        { 'id': 'l_storages_up_zoom', checked: true },
        { 'id': 'l_storages_down_zoom', checked: true },
        { 'id': 'l_storages_center_points', checked: false },
        { 'id': 'l_storages_center_points_icon', checked: false },
        { 'id': 'l_storages_clusters_points', checked: false },
        { 'id': 'l_storages_clusters_points_icon', checked: false },
        { 'id': 'l_storages_all', checked: true }

      ],
    }),

    created() {
      this.isVersion = JSON.parse(localStorage.getItem("storage:isVersion")) === 1 ;
      const sdt = this.item.storage_device_type_id;
      this.clone = _.cloneDeep(this.item);
      this.$set(this.clone, 'storage_device_type_id', sdt);
    },

    watch: {
      clone: {
        handler() {
          this.$emit('update:model', this.clone);
        },
        deep: true,
      },
      'clone.latitude': {
        handler(newVal) {
          const lat = parseFloat(newVal);
          const lng = parseFloat(this.clone.longitude);
          if (!isNaN(lat) && !isNaN(lng) && 
              lat >= -90 && lat <= 90 && 
              lng >= -180 && lng <= 180) {
            this.validateAndDrawPoint(lat, lng);
          }
        }
      },
      'clone.longitude': {
        handler(newVal) {
          const lat = parseFloat(this.clone.latitude);
          const lng = parseFloat(newVal);
          if (!isNaN(lat) && !isNaN(lng) && 
              lat >= -90 && lat <= 90 && 
              lng >= -180 && lng <= 180) {
            this.validateAndDrawPoint(lat, lng);
          }
        }
      },
      valid: {
        handler() {
            this.$emit('update:validation', this.valid)
        },
        deep: true
      },
      pointValidation: {
        handler(newValue) {
          if (newValue) {
            this.drawPoint();
          }
        },
        deep: true
      }
    },

    computed: {
      ...mapGetters({
        allUnits: "units/allObjectUnits",
        locationsList: "authority/authoritiesLocations",
        storages: "authority/authoritiesStoragesDevices",
        storageDeviceTypesActiveByCompany:
          "storage_type/storageDeviceTypesActiveByCompany",
        requireRules: "general/requireRules",
        latitudeRules: "general/latitudeRules",
        longitudeRules: "general/longitudeRules"
      }),
      pointValidation() {
        return this.clone.latitude && this.clone.latitude && this.valid;
      },
      itemColor: {
        get() {
          return this.$store.getters["general/color"]
        },
        set(val) {
          this.$store.commit("general/SET_COLOR", val)
        },
      },
      mapConfig: {
        get() {
          return this.$store.getters["general/mapConfig"]
        },
        set(val) {
          this.$store.commit("general/SET_MAP_CONFIG", val)
        },
      },
      config: {
        get() {
          return this.$store.getters["general/config"]
        },
        set(val) {
          this.$store.commit("general/SET_CONFIG", val)
        },
      },
      decimalFormat() {
        return this.config.company.traceability.structure.number.afterPoint !==
          null
          ? this.config.company.traceability.structure.number.afterPoint
          : 3
      },
    },

    methods: {
      ...mapActions({
        fetchAllUnits: "units/fetchAllUnits"
      }),
      async initialize() {
        this.loading = true
        await this.reload()
        this.loading = false
      },
      translate(key, uppercase) {
        return LanguageService.getKey3(key)
      },
      IsJsonString(json) {
        try {
          JSON.parse(json)
        } catch (e) {
          return false
        }
        return true
      },
      validateAndDrawPoint(lat, lng) {
        if (this.$refs.mapLocations && lat !== -1 && lng !== -1) {
          const geoJsonData = {
            "type": "FeatureCollection",
            "features": [
              {
                "type": "Feature",
                "properties": {},
                "geometry": {
                  "type": "Point",
                  "coordinates": [lng, lat]
                }
              }
            ]
          };
          this.$refs.mapLocations.addGeoJsonToDraw(geoJsonData);
          this.clone.location = JSON.stringify(geoJsonData);
        }
      },
      
      handleMapClick() {
        const data = this.$refs.mapLocations.getLongLatDrawn();
        this.clone.location = this.$refs.mapLocations.getLocationsDrawn();

        if (data[1] !== 0) {
          this.clone.latitude = parseFloat(data[1]).toFixed(6);
        } else {
          this.clone.latitude = -1;
          this.clone.area = 0;
        }

        if (data[0] !== 0) {
          this.clone.longitude = parseFloat(data[0]).toFixed(6);
        } else {
          this.clone.longitude = -1;
          this.clone.area = 0;
        }
      },
      zoomTo(features) {
        this.$refs.mapLocations.setMapZoom(16)
        this.$refs.mapLocations.zoomToFitBounds(features, 100)
        this.$refs.mapLocations.setMapZoom(20)
      },
      getCenter(feature) {
        return this.$refs.mapLocations.getCenter(feature)
      },
      fetchUnitObject(code) {
        return _.find(this.allUnits, { "code": code });
      },
      changeArea(area) {
        console.log(this.allUnits);
        const meter = this.fetchUnitObject("m²")
        const ha = this.fetchUnitObject("ha")

        console.log(meter)
        console.log(ha)

        if (meter && ha) {
          return this.unitConvert(
            area,
            meter,
            ha
          )
        } else {
          return 0;
        }
      },
      setDataFormLocations(area) {
        const data = this.$refs.mapLocations.getLongLatDrawn();
        this.clone.location = this.$refs.mapLocations.getLocationsDrawn();

        this.changeArea(area).then((res) => {
          console.log('>>> area (ha): ', res.data.quantity);
          console.log('>>> area (m2): ', area);
          this.clone.area = res.data.quantity;
        }).catch((err) => {
          this.clone.area = 0;
        })

        if (parseInt(data[1]) !== 0) {
          this.clone.latitude = data[1];
        } else {
          this.clone.latitude = -1;
        }

        if (parseInt(data[0]) !== 0) {
          this.clone.longitude = data[0];
        } else {
          this.clone.longitude = -1;
        }
      },
      changeMeasurementUnit(newMU, oldMU) {
        if (newMU && oldMU) {
          let area = this.clone.area
          if (this.clone.area > 0 && oldMU.code !== newMU.code) {
            area = this.unitConvert(area, oldMU, newMU)
            this.changedByMeasurementUnit = true
            this.clone.area = area
          }
          if (this.clone.area > 0) {
            this.areaWithUnit = this.clone.area
            this.areaSuffix = this.clone.measurementUnit
              ? _.find(this.allUnits, {
                  id: this.clone.measurementUnit.id,
                }).code
              : ""
          }
        }
      },
      unitConvert(quantity, from, to) {
        return N_UnitsService.convert(
          {
            unitsIn: [
              {
                quantity,
                unit: this.translate(from.languageKey)
              }
            ],
            unitOut: this.translate(to.languageKey)
          })
      },
      setStyle() {
        this.reload()
      },
      setColor() {
        this.reload(true)
      },
      setMapPaint() {
        this.mapConfig.layers = _.map(this.mapConfig.layers, item => {
          return {
            ...item,
            paint:
            item.id === "LocationPrimaryLayer"
                ? this.setPaint(item.paint)
                : item.paint,
          }
        })
      },
      setPaint(paints) {
        if (paints && paints["fill-color"]) {
          _.forEach(paints["fill-color"], (p, key) => {
            if (p === this.clone.locationId) {
              paints["fill-color"][key + 1] = hexToRgba(this.itemColor)
            }
          })
        }
        return paints
      },
      configLayerColor() {
        this.itemColor = this.clone.color
          ? this.clone.color
          : "rgba(175,0,34,0.4)"
      },
      async reload(colorReload) {
        this.units = this.allUnits
        if (localStorage.getItem("location_editOrSave") !== null) {
          this.editedIndex = localStorage.getItem("location_editOrSave")
        }
        this.$emit('update:turf', _.find(this.units, { code: "ha" }))
        this.clone.measurementUnit = this.clone.measurementUnit
          ? _.find(this.units, { id: this.clone.measurementUnit.id })
          : null
        this.areaSuffix = "ha"

        // Delete map config
        this.$refs.mapLocations.deleteMapConfig()

        // Get Data and config paint layer locations
        const featureLocations = await this.getDataLocations()

        // Get Data and config paint layer storages
        const featureStorages = await this.getDataStorages()

        // Set config sources and layers
        this.$refs.mapLocations.setMapConfig()

        // Update data source map
        this.$refs.mapLocations.updateSource('s_locations_clusters',featureLocations.allCenterPointsFeature);
        this.$refs.mapLocations.updateSource('s_locations',featureLocations.allFeatures);
        this.$refs.mapLocations.updateSource('s_storages_clusters',featureStorages.allCenterPointsFeature);
        this.$refs.mapLocations.updateSource('s_storages',featureStorages.allFeatures);

        // Add events to map
        this.addEventsMap();

        let locationClone = null
        if (this.clone.locationFormat === "GEOBUF") {
          locationClone = await MapService.singleLocationFormat(this.clone)
          this.$set(this, "clone", locationClone)
        }
        if (
          this.IsJsonString(this.clone.location) &&
          this.clone.location &&
          this.clone.location !== "null"
        ) {
          if (this.clone.area < 0) {
            this.$refs.mapLocations.updateAreaDrawn()
            this.clone.area = this.$refs.mapLocations.getAreaDrawn()
          }
          if(this.action !== 'show'){
            this.$refs.mapLocations.addGeoJsonToDraw(
              JSON.parse(this.clone.location)
            )
          }
          this.zoomTo(JSON.parse(this.clone.location))
        } else {
          this.zoomTo(featureLocations.allFeatures, 100)
        }
      },

      async getDataLocations() {
        let allFeatures = {
          type: "FeatureCollection",
          features: [],
        }
        let allCenterPointsFeature = {
          type: "FeatureCollection",
          features: [],
        }

        let location = null
        const mergeFormat = await MapService.mergeLocationsFormats(
          this.locationsList
        )
        for (const locationItem of mergeFormat) {
          if (
            this.IsJsonString(locationItem.location) &&
            locationItem.location &&
            locationItem.location !== "null"
          ) {
            location = JSON.parse(locationItem.location).features[0]
            if (location) {
              if (!location.properties) {
                location.properties = {}
              }
              location.properties.id = locationItem.locationId
              location.properties.gln = locationItem.gln
              location.properties.locationName = locationItem.name
              location.properties.telephone = locationItem.telephone
              location.properties.email = locationItem.email
              location.properties.area = locationItem.area
              location.properties.order = 2
              if (
                isValidLatLong(locationItem.latitude, true) &&
                isValidLatLong(locationItem.longitude, false)
              ) {
                location.properties.lat = convertLatitude(locationItem.latitude)
                location.properties.long = convertLongitude(locationItem.longitude)
              }
              location.properties.address =
                this.setAddressPopUpData(locationItem)
              location.properties.locationsNames = locationItem.name

              allFeatures.features.push(location)
              let point = turf.center(location)
              point.properties = location.properties
              allCenterPointsFeature.features.push(point)
            }
          }
        }
        return {
          allFeatures: allFeatures,
          allCenterPointsFeature: allCenterPointsFeature
        }
      },
      async getDataStorages() {
        let allFeatures = {
          type: "FeatureCollection",
          features: [],
        }
        let allCenterPointsFeature = {
          type: "FeatureCollection",
          features: [],
        }
        let location = null
        const mergeFormat = await MapService.mergeLocationsFormats(
          this.storages
        )
        for (const locationItem of mergeFormat) {
          if (
            this.IsJsonString(locationItem.location) &&
            locationItem.location &&
            locationItem.location !== "null"
          ) {
            location = JSON.parse(locationItem.location).features[0]
            if (location) {
              if (!location.properties) {
                location.properties = {}
              }
              location.properties.id = locationItem.storageDeviceId
              location.properties.gln = locationItem.gln
              location.properties.locationName = locationItem.control_label
              location.properties.telephone = locationItem.telephone
              location.properties.email = locationItem.email
              location.properties.area = locationItem.area
              location.properties.order = 1
              if (
                isValidLatLong(locationItem.latitude, true) &&
                isValidLatLong(locationItem.longitude, false)
              ) {
                location.properties.lat = convertLatitude(locationItem.latitude)
                location.properties.long = convertLongitude(locationItem.longitude)
              }
              location.properties.address =
                this.setAddressPopUpData(locationItem)
              location.properties.locationsNames =
                this.setStoragePopUpData(locationItem)

              allFeatures.features.push(location)
              let point = turf.center(location)
              point.properties = location.properties
              allCenterPointsFeature.features.push(point)
            }
          }
        }
        return {
          allFeatures: allFeatures,
          allCenterPointsFeature: allCenterPointsFeature
        }
      },
      addEventsMap() {
        this.$refs.mapLocations.onMouseClickClusterZoomLayer(
          "l_locations_clusters","s_locations_clusters"
        );
        this.$refs.mapLocations.onMouseClickClusterZoomLayer(
          "l_storages_clusters","s_storages_clusters"
        )


        this.$refs.mapLocations.onMouseHoverPopUp(
          [
            "l_storages_clusters_points_icon",
            "l_storages_clusters_points",
            "l_storages_center_points_icon",
            "l_storages_center_points",
            "l_storages_all",
            "l_locations_clusters_points_icon",
            "l_locations_clusters_points",
            "l_locations_center_points_icon",
            "l_locations_center_points",
            "l_locations_all"
          ],
          "pointer","layers"
        )
        this.$refs.mapLocations.onMouseLeavePopUp(
          [
            "l_storages_clusters_points_icon",
            "l_storages_clusters_points",
            "l_storages_center_points_icon",
            "l_storages_center_points",
            "l_storages_all",
            "l_locations_clusters_points_icon",
            "l_locations_clusters_points",
            "l_locations_center_points_icon",
            "l_locations_center_points",
            "l_locations_all"
          ]
        )

      },
      setAddressPopUpData(locationItem) {
        return (
          this.checkIfExist(locationItem.address1, true, ", ") +
          this.checkIfExist(locationItem.city, true, ", ") +
          this.checkIfExist(locationItem.state, true, ", ") +
          this.checkIfExist(locationItem.country, false)
        )
      },
      setStoragePopUpData(locationItem) {
        return (
          this.checkIfExist(locationItem.primaryLocation.name, true, " | ") +
          this.checkIfExist(locationItem.control_label, false)
        )
      },

      checkIfExist(target, continueTrue, separator) {
        target = target ? target : ""
        continueTrue = continueTrue && target !== "" ? separator : ""
        return target + continueTrue
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
      drawPoint() {
        if(!this.$refs.mapLocations.existDraw() && (this.clone.longitude !== -1 && this.clone.latitude !== -1)){
          const geoJsonData = {
            "type": "FeatureCollection",
            "features": [
              {
                "type": "Feature",
                "properties":{},
                "geometry": {
                  "type": "Point",
                  "coordinates": [parseFloat(this.clone.longitude), parseFloat(this.clone.latitude)]
                }
              }
            ]
          }
          this.$refs.mapLocations.addGeoJsonToDraw(geoJsonData)
          this.clone.location = JSON.stringify(geoJsonData);
        }
      }
    },
  }
</script>

<style scoped>
  :deep(.mapboxgl-map) {
    border-radius: 4px !important;
  }
</style>
