<template>
  <v-card class="py-2" color="transparent" elevation="0" outlined>
    <h3 class="my-0 py-0 d-flex mx-4">
      <strong>CAR: </strong>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <p class="ml-2 black--text" style="font-size: 16px" v-on="on">
            <strong
              >{{ items[0].documentType["document"]  }}
            </strong>
          </p>
        </template>
        <span>{{ items[0].documentType["document"] }}</span>
      </v-tooltip>
    </h3>
    <v-card-text
      v-if="items.length > 0"
      style="display: grid; grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); gap: 24px;"
    >
      <div v-for="(item, i) in items" :key="i" class="mb-4">
        <h3 class="ma-0 black--text">{{ getDatasetName(item.dataset) }}</h3>
        <div class="d-flex justify-space-between align-content-start align-start">
          <p class="ma-0 pa-0" style="font-size: 14px">
            {{ new Date(item.dateTime).toLocaleString() }}
          </p>
        </div>

        <SUCListComponent
          :items="item.result"
          v-if="item.dataset === 'SOIL_USE_CONSOLIDATED'"
        />
        <ComplianceListComponent
          :items="item.result"
          v-if="item.dataset === 'COMPLIANCE'"
        />
      </div>
    </v-card-text>
    <v-card-text v-else>
      {{ $t("common.fields.noDataAvailable") }}
    </v-card-text>
  </v-card>
</template>

<script>
  import ComplianceListComponent from "@/module/configuration/storage/components/integrations/BRAINAG/csView/CAR/ComplianceListComponent.vue"
  import SUCListComponent from "@/module/configuration/storage/components/integrations/BRAINAG/csView/CAR/SUCListComponent.vue"
  import _ from "lodash"
  import { mapGetters } from "vuex"

  export default {
    name: "CARComponent",
    components: { SUCListComponent, ComplianceListComponent },
    props: {
      items: {
        type: Array,
        required: true,
        default: () => {
          return []
        },
      },
    },

    computed: {
      ...mapGetters({
        documentType: "storage/documentTypes",
      }),
    },

    methods: {
      getDatasetName(dataset) {
        return _.find(this.documentType.datasets.car, { value: dataset }).name
      },
    },
  }
</script>

<style scoped>
  .v-chip.v-size--small,
  .v-chip.v-size--x-small {
    border-radius: 0.3em !important;
  }
  :deep(.v-expansion-panel-content__wrap) {
    padding: 0 0 16px !important;
  }
</style>
